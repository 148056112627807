import { Button } from "@mui/material";
import flyer from "../../assets/240301_stadtlüüd.png";
import vorplan from "../../assets/241206_planungsstand.jpg";
import funktionsplan from "../../assets/funktionsplan.png";
import uebersichtsplan from "../../assets/übersichtsplan.png";

interface Item {
  img: string;
  fileName: string;
  title: string;
  fileUrl: string;
}

const URL_API = process.env.REACT_APP_URL_API || ""; // Provide fallback for missing API URL

export const getFileUrl = (fileName: string): string => {
  if (!URL_API) {
    console.error("API URL is not defined");
    return "#"; // Fallback link if URL_API is missing
  }
  return `${URL_API}/downloads/${fileName}`;
};

const itemData: Item[] = [
  {
    img: flyer,
    fileName: "241208_stadtlüüd.pdf",
    title: "Flyer",
    fileUrl: getFileUrl("241208_stadtlüüd.pdf"),
  },
  {
    img: vorplan,
    fileName: "241206_planungsstand.pdf",
    title: "Vorplanung Stand 02.12.2024",
    fileUrl: getFileUrl("241206_planungsstand.pdf"),
  },
  {
    img: uebersichtsplan,
    fileName: "Übersichtsplan.pdf",
    title: "Übersichtsplan",
    fileUrl: getFileUrl("übersichtsplan.pdf"),
  },
  {
    img: funktionsplan,
    fileName: "Funktionsplan.pdf",
    title: "Funktionsplan",
    fileUrl: getFileUrl("funktionsplan.pdf"),
  },
];

const DownloadPdfButton = ({ item }: { item: Item }) => {
  const handleDownload = () => {
    if (item.fileUrl === "#") {
      alert("File URL is not available.");
      return;
    }
    window.open(item.fileUrl, "_blank");
  };

  return (
    <Button onClick={handleDownload} aria-label={`Download ${item.title}`} className="w-full">
      <img src={item.img} alt={`${item.title} thumbnail`} className="max-w-full" />
    </Button>
  );
};

const DownloadCard = ({ item }: { item: Item }) => (
  <div className="flex flex-col items-center justify-center">
    <DownloadPdfButton item={item} />
    <div className="mt-2 text-center">
      <span className="block text-sm font-medium">{item.title}</span>
    </div>
  </div>
);

const DownloadsContent = () => (
  <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-6">
    {itemData.map((item) => (
      <DownloadCard key={item.fileName} item={item} />
    ))}
  </div>
);

export const Downloads = {
  className: "downloads",
  titlePrimary: "Zum Mitnehmen",
  titleSecondary: "Dokumente und Informationen",
  content: <DownloadsContent />,
};

import ArrowCircleUpIcon from "@mui/icons-material/ArrowCircleUp";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { Link } from "react-scroll";

const text = (
  <div>
    Die Finazierung des Projekts wird staatlich gefördert. Die monatliche Kaltmiete liegt einkommensabhängig zwischen{" "}
    <strong>7,10 und 15,90 €/m²</strong>. Sie wird von der IFB Hamburg auf Basis der{" "}
    <Link activeClass="active" smooth spy to="whoWeSearch">
      <button className="underline decoration-transparent transition duration-300 ease-in-out hover:decoration-inherit hover:text-blue-500 whitespace-pre bg-transparent border-none cursor-pointer">
        Fallgruppen
        <ArrowCircleUpIcon />
      </button>
    </Link>{" "}
    festgelegt und dient zur Tilgung des Baukredits sowie zur Deckung der laufenden Kosten der Genossenschaft.
    <br />
    <br />
    Jeder Haushalt bringt Eigenmittel in Höhe von <strong>500 bis 700 €/m²</strong> ein, die ab Eintritt in die GbR
    schrittweise eingezahlt werden. Für diese Summe kann ein{" "}
    <a
      href="https://www.kfw.de/inlandsfoerderung/Privatpersonen/Neubau/F%C3%B6rderprodukte/Wohneigentumsprogramm-Genossenschaftsanteile-(134)"
      target="_blank"
      rel="noopener noreferrer"
      className="underline decoration-transparent transition duration-300 ease-in-out hover:decoration-inherit hover:text-blue-500 whitespace-pre"
    >
      zinsgünstiges Darlehen bei der KfW
      <OpenInNewIcon />
    </a>{" "}
    aufgenommen werden. Die Eigenmittel decken die Planungskosten sowie die Baukosten, die nicht durch Förderung und
    Kaltmiete abgedeckt sind.
  </div>
);

export const Costs = {
  className: "costs",
  titlePrimary: "Finanzierung",
  titleSecondary: "Kosten und Finanzierung",
  content: text,
};

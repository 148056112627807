import commonRoomPic from "../../assets/gemeinschaftsbereich_erdgeschoss.jpg";

const text = (
  <div className="relative">
    <figure className="float-right w-full md:w-1/3 ml-4 mb-4">
      <img src={commonRoomPic} alt="Das Stadtlüüd-Haus" className="rounded-lg shadow-lg" />
      <figcaption className="text-sm text-center mt-2">Gemeinschaftsbereich Erdgeschoss</figcaption>
    </figure>
    Es sollen Gemeinschaftsräume für Treffen, Feste und als Ort für Kinderspiele und unverbindliches Zusammenkommen im
    Erdgeschoss und auf der Dachterrasse realisiert werden. Die Einrichtung einer Küchenzeile ermöglicht gemeinsames
    Kochen und Essen. Kurze direkte Wege zwischen den Bewohnerinnen und Bewohnern sollen das Miteinander prägen, dafür
    bilden die Gemeinschaftsräume das Zentrum. Die Gemeinschaftsräume stehen allen Bewohnerinnen und Bewohner des Hauses
    offen. Es bestehen Überlegungen, den Gemeinschaftsraum im Erdgeschoss nach außen zu öffnen; in welcher Form dies
    geschehen soll, ist noch offen.
    <br />
    <br />
    Das Teilen von Infrastruktur soll sich in unserer Waschküche fortsetzen. Hier ist es möglich, Geräte kostensparend
    anzuschaffen sowie energiesparend und effizient zu nutzen. Wir wollen außerdem Werkzeuge teilen, um Ressourcen zu
    sparen. So ist es nicht notwendig, dass jeder Haushalt eine Bohrmaschine oder einen Werkzeugkasten besitzt. An
    gemeinsamen Projekten zusammenzuarbeiten stärkt den Zusammenhalt, „schweißen und löten zusammen“.
  </div>
);

export const CommonRooms = {
  className: "commonRooms",
  titlePrimary: "Gemeinschaftsräume",
  titleSecondary: "Gemeinschaftsräume: Begegnung, Zusammenarbeit und Nachhaltigkeit",
  content: text,
};

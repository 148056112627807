import OpenInNewIcon from "@mui/icons-material/OpenInNew";

const text = (
  <>
    <p>
      Ist dein Haushalt in Fallgruppe A4? Schreib uns gerne trotzdem – es kann vorkommen, dass ein Platz wieder frei
      wird.
      <br />
      <br />
      Der{" "}
      <a
        href="https://www.ifbhh.de/services/finanzass/einkommensrechner"
        target="_blank"
        rel="noopener noreferrer"
        className="underline decoration-transparent transition duration-300 ease-in-out hover:decoration-inherit hover:text-blue-500 whitespace-pre"
      >
        Einkommensrechner
        <OpenInNewIcon />
      </a>{" "}
      der IFB Hamburg hilft euch, eure Überschreitung der Einkommensgrenze gemäß § 8 HmbWoFG zu schätzen. Die
      Fallgruppen A1 bis A4 sind wie folgt definiert:
    </p>
    <br />
    <div className="w-full overflow-x-auto">
      <table className="table-auto border-collapse border border-gray-300 w-full">
        <thead>
          <tr className="bg-orange-200 dark:bg-orange-700 text-xs sm:text-sm md:text-lg">
            <th className="border border-gray-300 px-2 sm:px-4 py-2 dark:border-gray-600">Fallgruppe</th>
            <th className="border border-gray-300 px-2 sm:px-4 py-2 dark:border-gray-600">Belegungsquote</th>
            <th className="border border-gray-300 px-2 sm:px-4 py-2 dark:border-gray-600">Einkommensgrenze</th>
            <th className="border border-gray-300 px-2 sm:px-4 py-2 dark:border-gray-600">Anfangsmiete netto kalt</th>
          </tr>
        </thead>
        <tbody>
          <tr className="bg-gray-100 dark:bg-gray-700 text-xs sm:text-sm md:text-lg">
            <td className="border border-gray-300 px-2 sm:px-4 py-2 dark:border-gray-600"></td>
            <td className="border border-gray-300 px-2 sm:px-4 py-2 dark:border-gray-600">Anteil Haushalte</td>
            <td className="border border-gray-300 px-2 sm:px-4 py-2 dark:border-gray-600">Überschreitung</td>
            <td className="border border-gray-300 px-2 sm:px-4 py-2 dark:border-gray-600">m² Wfl. mtl.</td>
          </tr>
          <tr className="text-xs sm:text-sm md:text-lg">
            <td className="border border-gray-300 px-2 sm:px-4 py-2 dark:border-gray-600">A 1</td>
            <td className="border border-gray-300 px-2 sm:px-4 py-2 dark:border-gray-600">mind. 30 %</td>
            <td className="border border-gray-300 px-2 sm:px-4 py-2 dark:border-gray-600">bis 60 %</td>
            <td className="border border-gray-300 px-2 sm:px-4 py-2 dark:border-gray-600">7,10 €</td>
          </tr>
          <tr className="bg-gray-100 dark:bg-gray-700 text-xs sm:text-sm md:text-lg">
            <td className="border border-gray-300 px-2 sm:px-4 py-2 dark:border-gray-600">A 2</td>
            <td className="border border-gray-300 px-2 sm:px-4 py-2 dark:border-gray-600">variabel</td>
            <td className="border border-gray-300 px-2 sm:px-4 py-2 dark:border-gray-600">bis 100 %</td>
            <td className="border border-gray-300 px-2 sm:px-4 py-2 dark:border-gray-600">9,20 €</td>
          </tr>
          <tr className="text-xs sm:text-sm md:text-lg">
            <td className="border border-gray-300 px-2 sm:px-4 py-2 dark:border-gray-600">A 3</td>
            <td className="border border-gray-300 px-2 sm:px-4 py-2 dark:border-gray-600">variabel</td>
            <td className="border border-gray-300 px-2 sm:px-4 py-2 dark:border-gray-600">bis 140 %</td>
            <td className="border border-gray-300 px-2 sm:px-4 py-2 dark:border-gray-600">12,10 €</td>
          </tr>
          <tr className="bg-gray-100 dark:bg-gray-700 text-xs sm:text-sm md:text-lg">
            <td className="border border-gray-300 px-2 sm:px-4 py-2 dark:border-gray-600">A 4</td>
            <td className="border border-gray-300 px-2 sm:px-4 py-2 dark:border-gray-600">höchstens 20 %</td>
            <td className="border border-gray-300 px-2 sm:px-4 py-2 dark:border-gray-600">über 140 %</td>
            <td className="border border-gray-300 px-2 sm:px-4 py-2 dark:border-gray-600">15,90 €</td>
          </tr>
        </tbody>
      </table>
    </div>
  </>
);

export const WhoWeSearch = {
  className: "whoWeSearch",
  titlePrimary: "Wen wir suchen",
  titleSecondary: "Wen wir derzeit suchen: Menschen aus den Fallgruppen A1 - A3.",
  content: text,
};
